import React, { useState } from "react";
import "./index.css";
import webMobile from "../../images/services-page/Web and Mobile.jpg";
import desktop from "../../images/services-page/product-dev.png";
import cyber from "../../images/services-page/VPAT-cyber.png";
import ITservices from "../../images/services-page/IT-Manage.png";
import system from "../../images/services-page/System-Integration.jpg";
import Cert from "../../images/services-page/IT-Cert.png";
import consult from "../../images/services-page/consult.jpg";
import bootcamp from "../../images/services-page/bootcamp.jpg";
import cloudComputing from "../../images/services-page/Cloud.jpg";
import { Row, Col, Button } from "reactstrap";
import { AiOutlineSearch } from "react-icons/ai";
import OurExpertise from "./_components/our-expertise";

const data = [
  {
    Image: webMobile,
    title: "Web & Mobile Development",
    desc: `We understand that getting a website design that fits the needs and budget can be a daunting task. This is why we offer a wide range of creative web design solutions to deliver a real return on our client's investment. The technologies we are expert with React. Js, Node. Js, Express. Js, Next.JS, Asp.Net, MVC, PHP (CodeIgniter & Laravel).`,
    desc2: `In the ever-changing world invention of mobile phones has revolutionized the way we live. It is hard to imagine a day without a mobile phone because they make everybody easily accessible. The use of cell phones has changed over the years from mere calling others to day to day activities including different tasks, especially for business users. If we go deep into what has changed the usage of mobile phones altogether, mobile applications will pop up. There are so many amazing apps available which carry out different functions. The Technologies we are expert with Android, Flutter and React Native.`,
  },

  {
    Image: cyber,
    title: "VAPT & Cyber Security Services",
    desc: `Imagine your network as a medieval fortress, its secrets protected by vigilant guards with hawk-like eyes. At Pie-Tech, we are those guards, deploying VAPT like advanced perimeter scanners to uncover hidden vulnerabilities—poison arrows aimed at your castle walls. Our skilled penetration testers then launch simulated sieges, mimicking real-world phishing attacks and social engineering exploits, hardening your defences before any true attackers breach your data. But our protection goes beyond the initial battle. We offer specialized teams for forensics, incident response, and threat intelligence, standing ready to repel any further incursions and keep your digital kingdom safe. With Pie-Tech, your network becomes not just a strong castle, but an impregnable fortress, impenetrable to even the most cunning digital enemies.`,
  },
  {
    Image: desktop,
    title: "Application and Product Development",
    desc: `We provide you with an efficient, customized and user-friendly applications that can run both offline and independent of web browser. People meet their respective business requirements with a competitive advantage in the growing market with the help of our desktop application. Different businesses have different and unique requirements, through our custom-developed applications and products, we help businesses to fulfill the requirements and achieve success. The technologies we are expert with C#, .Net, Java.    `,
  },
  {
    Image: cloudComputing,
    title: "Cloud & Cloud Native",
    desc: `Cloud computing services have revolutionized the way businesses and individuals’ access and manage data, applications, and computing resources. Cloud native computing take this paradigm a step further, emphasizing the development and deployment of applications designed specifically for the cloud environment.`,
    desc2: `Embrace agility, scalability & innovation with Cloud & Cloud Native solutions. Break free from hardware & unleash your creativity. Scale instantly, build modern apps & unlock valuable insights. Let our experts guide your cloud journey - contact us today!`,
  },
  {
    Image: ITservices,
    title: "IT Managed Services",
    desc: `Ditch the IT headaches and unlock peak performance with Pie-Tech's IT Managed Services. We're your proactive guardian angels, from Devops pipelines to Mac desktops, predicting and resolving issues before they disrupt you. No more scrambling - we speak your tech language, Linux, Windows, anything, optimizing your systems for maximum efficiency, minimal downtime, and peace of mind. Focus on your business, watch your costs shrink, and let Pie-Tech transform your IT from burden to a powerful engine for success. Leave the tech to us, focus on what you do best, and watch your business soar.`,
  },
  {
    Image: system,
    title: "System Integrations",
    desc: `Picture your data scattered like lost jewels, hidden within siloed systems. Pie-Tech's System Integrations act as skilled jewellers, meticulously combining them into a dazzling crown of efficiency. We break down data walls, forge automated workflows, and make your tools sing in harmony. No more manual drudgery - your software becomes a symphony, amplifying productivity and unleashing its full potential. Ditch the data islands, let Pie-Tech polish your digital gem, and watch your business shine brighter than ever.`,
  },
  {
    Image: Cert,
    title: "IT Trainings and Certifications",
    desc: `Getting ready to move on or up in your IT career, but can’t find the right opportunities? Maybe you should invest in yourself, to encourage others to invest in you, too. Taking advanced training classes and building your IT certification portfolio are two great ways to stand out from the rest of the IT crowd. We offer high class IT trainings through our experienced and certified trainers to help you get certified in different domains including Big Data Analytics, AI and Machine Learning, Web Development, Mobile Application Development, Cyber Security, Programming, Scrum Framework, Block chain, Mern/ Mean Stack Development, Cyber Security and Ethical Hacking, Data Sciences, Machine Learning & AI, Block Chain, Cloud and Native Computing, SQA, SEO and Digital Marketing, Graphics Designing and Video Editing.`,
  },
  {
    Image: bootcamp,
    title: "Boot Camp Training & Management",
    desc: `We understand the requirements of training boot camps for graduates and professionals and we provide the complete execution mechanism of boot camps with the following supports. Training Venues, Computing facilities, Trainers and Assistant Trainers, Management Staff, Refreshment and Meal, Other Compliance Support.`,
  },
  {
    Image: consult,
    title: "HR & Consultancy Services",
    desc: `Agile and platform driven consultancy and HR outsourcing services to capture your business and management challenges and continue the business growth while maintaining compliance. Forget about resource recruiting, hiring & retaining, talent management, training and development, compensation and employees benefits, the outsourcing of HR includes the resources of the Project management, software development, cyber security and cloud native computing, IT relevant trainings and Boot Camps Management.`,
  },
];

const Services = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredData = data.filter(
    (item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.desc.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const [showSearch, setShowSearch] = useState(false);

  const toggleSearch = () => {
    setShowSearch(!showSearch);
  };

  return (
    <div className="container-fluid main" style={{ marginTop: "6.1rem" }}>
      <Row className="gap-5 justify-content-center">
        <div>
          <Row className="justify-content-center align-items-center">
            <Col
              xs={0}
              sm={1}
              md={1}
              lg={1}
              className="d-flex justify-content-center"
            >
              {/* <h1 style={{ color: "#f7a31c", marginTop: "2rem", fontWeight: "bolder" }}>Our Services</h1> */}
            </Col>
            <Col
              xs={10}
              sm={10}
              md={10}
              lg={10}
              className="d-flex justify-content-center"
            >
              <h1
                style={{
                  color: "#333f52",
                  marginTop: "2rem",
                  fontWeight: "bolder",
                }}
              >
                Our Services
              </h1>
            </Col>
            <Col
              xs={1}
              sm={1}
              md={1}
              lg={1}
              className="SearchCol d-flex justify-content-end"
            >
              {showSearch ? (
                <input
                  style={{
                    marginTop: "1rem",
                    fontWeight: "bolder",
                    height: "2rem",
                    width: "10rem",
                  }}
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              ) : (
                <Button
                  className="mt-4"
                  style={{ backgroundColor: "#f7a31c" }}
                  onClick={toggleSearch}
                >
                  <AiOutlineSearch />
                </Button>
              )}
            </Col>
          </Row>
        </div>

        {filteredData.map((dat, id) => {
          return (
            <Col
              key={id}
              xs="12"
              md="6"
              lg="4"
              className="Back bg-light shadow"
              style={{
                marginTop: "1rem",
                marginBottom: "3rem",
                borderRadius: "7px",
              }}
            >
              <img
                className="service-icon"
                src={dat.Image}
                alt="loading"
                style={{
                  width: "100%",
                  objectFit: "cover",
                  marginTop: "12px",
                  marginBottom: "12px",
                  borderRadius: "7px",
                }}
              />
              <p
                id="Head"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {dat.title}
              </p>
              <div style={{ fontSize: "14px" }}>
                <div
                  className="container the-desc"
                  style={{
                    pointerEvents: "none",
                    marginBottom: "2rem",
                    textAlign: "justify",
                  }}
                >
                  {dat.desc}
                  <br />
                  <br />
                  {dat?.desc2}
                </div>
              </div>
            </Col>
          );
        })}
      </Row>

      <OurExpertise />
    </div>
  );
};

export default Services;
