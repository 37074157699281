import image1 from "../../images/mindhubIT/Image1.png";
import image2 from "../../images/mindhubIT/Image2.png";
import image3 from "../../images/mindhubIT/Image3.jpeg";
import image4 from "../../images/mindhubIT/Image4.png";
import image5 from "../../images/mindhubIT/Image5.png";
import image6 from "../../images/mindhubIT/Image6.jpeg";
import image7 from "../../images/mindhubIT/Image7.png";
import image8 from "../../images/mindhubIT/Image8.png";
import image9 from "../../images/mindhubIT/Image9.png";
import image10 from "../../images/mindhubIT/Image10.png";
import image11 from "../../images/mindhubIT/Image11.png";
import image12 from "../../images/mindhubIT/Image12.png";
import image13 from "../../images/mindhubIT/Image13.png";
import image14 from "../../images/mindhubIT/Image14.png";
import image15 from "../../images/mindhubIT/Image15.png";
import image16 from "../../images/mindhubIT/Image16.png";
import image17 from "../../images/mindhubIT/Image17.png";
import image18 from "../../images/mindhubIT/Image18.png";
import image19 from "../../images/mindhubIT/Image19.png";
import image20 from "../../images/mindhubIT/Image20.png";
import image21 from "../../images/mindhubIT/Image21.png";
import image22 from "../../images/mindhubIT/Image22.png";
import image23 from "../../images/mindhubIT/Image23.png";
import image24 from "../../images/mindhubIT/Image24.png";
import image25 from "../../images/mindhubIT/Image25.png";
import image26 from "../../images/mindhubIT/Image26.png";
import image27 from "../../images/mindhubIT/Image27.png";
import image28 from "../../images/mindhubIT/Image28.png";
import image29 from "../../images/mindhubIT/Image29.png";
import image30 from "../../images/mindhubIT/Image30.png";
import image31 from "../../images/mindhubIT/Image31.png";
import image32 from "../../images/mindhubIT/Image32.png";
import image33 from "../../images/mindhubIT/Image33.png";
import image34 from "../../images/mindhubIT/Image34.png";
import image35 from "../../images/mindhubIT/Image35.png";
import image36 from "../../images/mindhubIT/Image36.png";
import image37 from "../../images/mindhubIT/Image37.png";
import image38 from "../../images/mindhubIT/Image38.png";
import image39 from "../../images/mindhubIT/Image39.png";
import image40 from "../../images/mindhubIT/Image40.png";
import image41 from "../../images/mindhubIT/Image41.png";
import image42 from "../../images/mindhubIT/Image42.png";
import image43 from "../../images/mindhubIT/Image43.png";
import image44 from "../../images/mindhubIT/Image44.png";
import image45 from "../../images/mindhubIT/Image45.jpeg";
import image46 from "../../images/mindhubIT/Image46.jpeg";
import image47 from "../../images/mindhubIT/Image47.jpeg";
import image48 from "../../images/mindhubIT/Image48.jpeg";
import image49 from "../../images/mindhubIT/Image49.jpeg";
import image50 from "../../images/mindhubIT/Image50.jpeg";
import image51 from "../../images/mindhubIT/Image51.jpeg";
import image52 from "../../images/mindhubIT/Image52.jpeg";
import image53 from "../../images/mindhubIT/Image53.jpeg";
import image54 from "../../images/mindhubIT/Image54.jpeg";
import image55 from "../../images/mindhubIT/Image55.jpeg";
import image56 from "../../images/mindhubIT/Image56.jpeg";
import image57 from "../../images/mindhubIT/Image57.jpeg";
import image58 from "../../images/mindhubIT/Image58.jpeg";

const data = [
  {
    name: "IT Specialist Certification Exam Voucher - JavaScript",
    price: "$62.00",
    image: image1,
  },
  {
    name: "IT Specialist Certification Exam Voucher - Python",
    price: "$62.00",
    image: image2,
  },
  {
    name: "IT Specialist Certification Exam Voucher - Computational Thinking",
    price: "$62.00",
    image: image3,
  },

  {
    name: "IT Specialist Certification Exam Voucher - Databases",
    price: "$62.00",
    image: image4,
  },
  {
    name: "IT Specialist Certification Exam Voucher - Network Security",
    price: "$62.00",
    image: image5,
  },
  {
    name: "IT Specialist Certification Exam Voucher - Artificial Intelligence",
    price: "$62.00",
    image: image6,
  },
  {
    name: "IT Specialist Certification Exam Voucher - Software Development",
    price: "$62.00",
    image: image7,
  },

  {
    name: "IT Specialist Certification Exam Voucher - HTML5 App Development",
    price: "$62.00",
    image: image8,
  },
  {
    name: "IT Specialist Certification Exam Voucher - Networking",
    price: "$62.00",
    image: image9,
  },
  {
    name: "IT Specialist Certification Exam Voucher - HTML and CSS",
    price: "$62.00",
    image: image10,
  },
  {
    name: "IT Specialist Certification Exam Voucher - Device Configuration and Management",
    price: "$62.00",
    image: image11,
  },

  {
    name: "IT Specialist Certification Exam Voucher - Cloud Computing",
    price: "$62.00",
    image: image12,
  },
  {
    name: "IT Specialist Certification Exam Voucher - Cybersecurity",
    price: "$62.00",
    image: image13,
  },
  {
    name: "IT Specialist Certification Exam Voucher - Data Analytics",
    price: "$62.00",
    image: image14,
  },
  {
    name: "IT Specialist Certification Exam Voucher - Java",
    price: "$62.00",
    image: image15,
  },

  {
    name: "IT Specialist HTML5 App Development Practice Test: INF-306",
    price: "$75.00 - $85.00",
    image: image16,
  },
  {
    name: "IT Specialist Java Practice Test: INF-304",
    price: "$75.00 - $85.00",
    image: image17,
  },
  {
    name: "IT Specialist Python Practice Test: INF-303",
    price: "$75.00 - $85.00",
    image: image18,
  },
  {
    name: "IT Specialist JavaScript Practice Test: INF-302",
    price: "$75.00 - $85.00",
    image: image19,
  },
  {
    name: "IT Specialist Networking Practice Test: INF-101",
    price: "$75.00 - $85.00",
    image: image20,
  },
  {
    name: "IT Specialist Cloud Computing Practice Test: INF-104",
    price: "$75.00 - $85.00",
    image: image21,
  },
  {
    name: "IT Specialist Artificial Intelligence Practice Test: INF-307",
    price: "$75.00 - $85.00",
    image: image22,
  },
  {
    name: "IT Specialist HTML and CSS Practice Test: INF-301",
    price: "$75.00 - $85.00",
    image: image23,
  },
  {
    name: "IT Specialist Databases Practice Test: INF-201",
    price: "$75.00 - $85.00",
    image: image24,
  },
  {
    name: "IT Specialist Network Security Practice Test: INF-102",
    price: "$75.00 - $85.00",
    image: image25,
  },
  {
    name: "IT Specialist Device Configuration and Management Practice Test: INF-103",
    price: "$75.00 - $85.00",
    image: image26,
  },
  {
    name: "IT Specialist Software Development Practice Test: INF-305",
    price: "$75.00 - $85.00",
    image: image27,
  },
  {
    name: "IT Specialist Computational Thinking Practice Test: INF-308",
    price: "$75.00 - $85.00",
    image: image28,
  },
  {
    name: "IT Specialist Cybersecurity Practice Test: INF-105",
    price: "$75.00 - $85.00",
    image: image29,
  },
  {
    name: "CertPREP Courseware: IT Specialist Cybersecurity - Self-Paced",
    price: "$150.00",
    image: image30,
  },
  {
    name: "CertPREP Courseware: IT Specialist Software Development - Self-Paced",
    price: "$150.00",
    image: image31,
  },
  {
    name: "CertPREP Courseware: IT Specialist Java - Self-Paced",
    price: "$150.00",
    image: image32,
  },
  {
    name: "CertPREP Courseware: IT Specialist Computational Thinking - Self-Paced",
    price: "$150.00",
    image: image33,
  },
  {
    name: "CertPREP Courseware: IT Specialist Device Configuration and Management - Self-Paced",
    price: "$150.00",
    image: image34,
  },
  {
    name: "CertPREP Courseware: IT Specialist Data Analytics Self-Paced (INF-202) 180-Day access",
    price: "$150.00",
    image: image35,
  },
  {
    name: "CertPREP Courseware: IT Specialist Artificial Intelligence - Self-Paced",
    price: "$150.00",
    image: image36,
  },
  {
    name: "CertPREP Courseware: IT Specialist Python - Self-Paced",
    price: "$150.00",
    image: image37,
  },
  {
    name: "CertPREP Courseware: IT Specialist JavaScript - Self-Paced",
    price: "$150.00",
    image: image38,
  },
  {
    name: "CertPREP Courseware: IT Specialist Network Security - Self-Paced",
    price: "$150.00",
    image: image39,
  },
  {
    name: "CertPREP Courseware: IT Specialist Cloud Computing - Self-Paced",
    price: "$150.00",
    image: image40,
  },
  {
    name: "CertPREP Courseware: HTML5 Application Development (INF-306) - Self-Paced",
    price: "$150.00",
    image: image41,
  },
  {
    name: "CertPREP Courseware: IT Specialist Databases - Self-Paced",
    price: "$150.00",
    image: image42,
  },
  {
    name: "CertPREP Courseware: IT Specialist HTML and CSS - Self-Paced",
    price: "$150.00",
    image: image43,
  },
  {
    name: "CertPREP Courseware: IT Specialist Networking - Self-Paced",
    price: "$150.00",
    image: image44,
  },
  {
    name: "IT Specialist Certification Bundle – Python",
    price: "$162.00",
    image: image45,
  },

  {
    name: "IT Specialist Certification Bundle – Artificial Intelligence",
    price: "$212.00",
    image: image46,
  },

  {
    name: "IT Specialist Certification Bundle – Cloud Computing",
    price: "$212.00",
    image: image47,
  },

  {
    name: "IT Specialist Certification Bundle – Java",
    price: "$212.00",
    image: image48,
  },
  {
    name: "IT Specialist Certification Bundle – Software Development",
    price: "$212.00",
    image: image49,
  },
  {
    name: "IT Specialist Certification Bundle – JavaScript",
    price: "$212.00",
    image: image50,
  },
  {
    name: "IT Specialist Certification Bundle – Network Security",
    price: "$212.00",
    image: image51,
  },
  {
    name: "IT Specialist Certification Bundle – Device Configuration and Management",
    price: "$277.00",
    image: image52,
  },
  {
    name: "IT Specialist Certification Bundle – Databases",
    price: "$277.00",
    image: image53,
  },
  {
    name: "IT Specialist Certification Bundle – Networking",
    price: "$277.00",
    image: image54,
  },
  {
    name: "IT Specialist Certification Bundle – Computational Thinking",
    price: "$277.00",
    image: image55,
  },
  {
    name: "IT Specialist Certification Bundle – HTML and CSS",
    price: "$277.00",
    image: image56,
  },
  {
    name: "IT Specialist Certification Bundle – HTML5 Application Development",
    price: "$277.00",
    image: image57,
  },
  {
    name: "IT Specialist Certification Bundle – Cybersecurity",
    price: "$277.00",
    image: image58,
  },
];

export default data;
