import React from "react";
import MainPageCarousel from "./_components/carousel";
import AboutMissionVision from "./_components/about-mission-vision";
import ServicesMain from "./_components/services";
import PartnerAffiliates from "./_components/partners-affiliations";
import LearningCategories from "./_components/learning-categories";
import Statistics from "./_components/statistics";
import PartnerOrganizations from "./_components/partner-organizations";
import ImagePopup from "../../components/popup/popup";

const MainPage = () => {
  return (
    <div style={{ margin: 0, padding: 0 }}>
      {/* <ImagePopup/> */}
      <MainPageCarousel />
      <AboutMissionVision />
      <ServicesMain />
      <PartnerAffiliates />
      <LearningCategories />
      <Statistics />
      <PartnerOrganizations />
    </div>
  );
};

export default MainPage;
