import React from "react";
import classIcon from "../../../../images/training-icons/trainIcons/classroom-live.svg";
import virtualIcon from "../../../../images/training-icons/trainIcons/virtual-classroom.svg";
import demandIcon from "../../../../images/training-icons/trainIcons/on-demand.svg";
import blendedIcon from "../../../../images/training-icons/trainIcons/blended-learning-icon-2-color.svg";
import { Row, Col } from "reactstrap";
import "./index.css";
const data = [
  {
    icon: classIcon,
    title: "Classroom Live",
    list1: "Interact face-to-face with subject matter experts.",
    list2:
      "Acquire skills in a ready-to-learn environment with state-of-the-art equipment.",
    list3: "Training locations located around the world.",
  },
  {
    icon: virtualIcon,
    title: "Virtual Classroom Live",
    list1:
      "Experience live instructor-led online training from the location of your choice.",
    list2:
      "Participate in exercises and collaborate with peers, similar to a physical classroom.",
    list3:
      "Access indexed and searchable class recordings and materials for 12 months.",
  },
  {
    icon: demandIcon,
    title: "On-Demand Training",
    list1:
      "Convenient 24/7 access to instructor-led videos and hands-on activities.",
    list2: "Training that fits into your schedule.",
    list3: "Learn at your own pace and on any device.",
  },
  {
    icon: blendedIcon,
    title: "Blended Live",
    list1: "Includes the structure and pacing of instructor-led training.",
    list2: "Features the flexibility of on-demand learning.",
    list3:
      "Allows you to maintain your day to day responsibilities and close critical skills gaps more effectively.",
    // list4: "Allows you to close critical skills gaps more effectively.",
  },
];
const TrainCards = () => {
  return (
    <div className="" style={{ color: "black", marginBottom: "10rem" }}>
      <Row
        className="justify-content-center gap-5"
        style={{ marginBottom: "-5rem" }}
      >
        <Col lg={12} md={12} sm={12}>
          <h4
            style={{
              textAlign: "center",
              color: "#333f52",
              fontWeight: "bold",
            }}
          >
            Get Professional Trainings,{" "}
            <span style={{ color: "#f7a31c" }}>
              Where you want , The way you want.
            </span>
          </h4>
        </Col>
        {data.map((dat, id) => {
          return (
            <Col
              lg={5}
              md={5}
              sm={12}
              className="bg-light d-flex align-items-center CardCol shadow"
              style={{ borderRadius: "5px" }}
              key={id}
            >
              <Row className="d-flex align-items-center">
                <Col
                  lg={4}
                  md={12}
                  sm={12}
                  className="container d-flex flex-column align-items-center"
                >
                  <img
                    src={dat.icon}
                    alt="loading"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      border: "2px solid grey",
                      borderRadius: "50%",
                    }}
                  />
                  <p
                    className="my-2"
                    style={{ textAlign: "center", fontSize: "1.2rem" }}
                  >
                    {dat.title}
                  </p>
                </Col>
                <Col lg={7} md={12} sm={12} className="my-1">
                  <ul style={{ fontSize: "15px", margin: "2rem" }}>
                    <li>{dat.list1}</li>
                    <li>{dat.list2}</li>
                    <li>{dat.list3}</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default TrainCards;
