import React from "react";
import nowsheraImage from "../../images/event-img/nowshera.jpeg";
import buraqOneImage from "../../images/event-img/buraqOne.jpeg";
import buraqTwoImage from "../../images/event-img/buraqTwo.jpeg";
import ReactImg from "../../images/event-img/React.jpeg";
import BigDataImg from "../../images/event-img/Big Data.jpeg";
import ApplyButtonMain from "../../components/apply-button";

// const advertisements = [
//   {
//     title: "🪂 Exciting Opportunity for Youth (18-40 years) 🪂",
//     typeof: "high-end",
//     courses: [
//       { course: "Certificate in IT (Web Development)", status: "Closed" },
//       { course: "Blockchain Programming", status: "Open" },
//       { course: "Digital Marketing and SEO", status: "Open" },
//     ],
//     limitedSeats: true,
//     whyJoin: "Learn in-demand skills and boost your career prospects",
//     applyLink: "https://nsis.navttc.gov.pk/sign-up",
//     eligibility: ["Youth aged 18 - 40 years"],
//     otherDetails: [
//       "Duration (3 Months)",
//       "Afternoon Session",
//       "Last date for apply is 14th Jan, 2024.",
//     ],
//     contactInfo:
//       "Call us at 0345-5444767, 0346-1201354  or visit us at Northern University Main Campus (Wattar Walai Ziarat, Kaka Sahib Road, Nowshera, KPK)",
//   },
//   {
//     title: "🪂 Exciting Opportunity for Youth (Up To 40 years) 🪂",
//     typeof: "high-tech",
//     courses: [
//       { course: "Project Management", status: "Closed" },
//       { course: "Big Data Analytics", status: "Closed" },
//     ],
//     limitedSeats: true,
//     whyJoin: "Learn in-demand skills and boost your career prospects",
//     applyLink: "https://nsis.navttc.gov.pk/sign-up",
//     eligibility: [
//       "Youth aged up to 40 years",
//       "Must be Graduates (16-year degree completed)",
//     ],
//     otherDetails: ["Duration (3 Months)", "Evening Sessions"],
//     contactInfo:
//       "Call us at 0345-5664557, 0345-5444767 or visit us at BIIT campus (106A-1, Murree Road, Block A, Satellite Town, Rawalpindi)",
//   },
//   {
//     title: "🪂 Exciting Opportunity for Youth  (Up To 40 years) 🪂",
//     typeof: "high-tech",
//     courses: [
//       { course: "React Native", status: "Open" },
//       { course: "Full Stack Development", status: "Open" },
//     ],
//     limitedSeats: true,
//     whyJoin: "Learn in-demand skills and boost your career prospects",
//     applyLink: "https://nsis.navttc.gov.pk/sign-up",
//     eligibility: [
//       "Youth aged up to 40 years",
//       "Must be Graduates (16-year degree completed)",
//     ],
//     otherDetails: [
//       "Duration (3 Months)",
//       "Afternoon / Evening Sessions",
//       "Last date for apply is 14th Jan, 2024.",
//     ],
//     contactInfo:
//       "Call us at 0331-8131581, 0333-5020126 or visit us at Buraq Centre, 2nd Floor, 11-D, 6th Road, Satellite Town, Rawalpindi",
//   },
// ];

const Events = () => {
  return (
    <div className="container mt-5 d-flex flex-column align-items-center mb-5">
      <ApplyButtonMain />
      <h1
        className="text-center mb-4"
        style={{ color: "#f7a31c", fontWeight: "bolder", marginTop: "5rem" }}
      >
        Latest Event
      </h1>

      {/* <Row className="justify-content-center">
        {advertisements.map((ad, index) => (
          <Col key={index} sm={4} className="mb-4">
            <div
              className="p-4 d-flex flex-column h-100"
              style={{ border: "2px solid #f7a31c", borderRadius: "10px" }}
            >
              <h2 style={{ color: "#f7a31c" }}>{ad.title}</h2>
              <h4 className="text-center" style={{ color: "#f7a31c" }}>
                {ad.typeof === "high-end"
                  ? "High-End Courses"
                  : "High-Tech Courses"}
              </h4>
              <p>
                🛸 <strong>Free Skill Development Courses</strong> by
                Pie-Technologies in collaboration with NAVTTC!
              </p>
              <p>
                👨‍💻 <strong>Courses Offered:</strong>
                <br />
                {ad.courses.map((course, i) => (
                  <span key={i}>
                    {i + 1}.{" "}
                    <strong style={{ color: "#f7a31c" }}>
                      {course.course}
                      <span
                        className="text-dark"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        {" "}
                        ({course.status})
                      </span>
                    </strong>
                    <br />
                  </span>
                ))}
              </p>
              {ad.limitedSeats && (
                <p>
                  📆 <strong>Limited Seats Available - Act Fast!</strong>
                </p>
              )}
              <p>
                ✨ <strong>Why Join?</strong>
                <br />
                {ad.whyJoin}
              </p>
              <p>
                📝 <strong>How to Apply:</strong>{" "}
                <a
                  href={ad.applyLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Apply Here
                </a>
              </p>
              <p>
                🎓 <strong>Who Can Apply?</strong>
                <br />
                {ad.eligibility.map((el, i) => (
                  <span key={i}>
                    {el}
                    <br />
                  </span>
                ))}
              </p>
              <p>
                ⛲ <strong>Other Details</strong>
                <br />
                {ad.otherDetails.map((detail, i) => (
                  <span key={i}>
                    {detail}
                    <br />
                  </span>
                ))}
              </p>
              <p>
                🔗 <strong>More Info:</strong>
                <br />
                {ad.contactInfo}
              </p>
            </div>
          </Col>
        ))}
      </Row> */}
      <div className="container row">
        <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center">
          <img
            className="py-3"
            src={ReactImg}
            alt="loading"
            style={{ height: "100%", width: "100%" }}
          />
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center">
          <img
            className="py-3"
            src={BigDataImg}
            alt="loading"
            style={{ height: "100%", width: "100%" }}
          />
        </div>
      </div>

      {/* <h1
        className="text-center mb-4"
        style={{ color: "#f7a31c", fontWeight: "bolder", marginTop: "5rem" }}
      >
        Previous Events
      </h1>
      <div className="container row">
        <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center">
          <img
            className="py-3"
            src={buraqOneImage}
            alt="loading"
            style={{ height: "100%", width: "100%" }}
          />
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center">
          <img
            className="py-3"
            src={buraqTwoImage}
            alt="loading"
            style={{ height: "100%", width: "100%" }}
          />
        </div>
      </div>
      <div className="row container d-flex justify-content-center">
        <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center">
          <img
            className="py-3"
            src={nowsheraImage}
            alt="loading"
            style={{ height: "100%", width: "100%" }}
          />
        </div>
      </div>*/}
    </div> 
  );
};

export default Events;
