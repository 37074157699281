import React from "react";
import { Col, Row } from "reactstrap";
import logo from "../../../images/Veritcal_logo 2.png";
import Reveal from "../../../components/animations/Reveal";
import ScaleReveal from "../../../components/animations/ScaleReveal";

const PortfolioABoutUs = () => {
  return (

    <Row className=" border-bottom px-4 mt-2" style={{display:"flex", justifyContent:"center"}}>

      <Col xl={12} lg={12} md={0} sm={0} xs={0} className="d-md-none d-sm-none d-xs-none"  //make this hide when screen is md or lower
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      >
        <ScaleReveal>
          <img
            src={logo}
            style={{ height: "20rem", width: "20rem" }}
            alt="PieTechnologies"
          />
        </ScaleReveal>
      </Col>



      <Col xl={6} lg={6} md={12} sm={12} xs={12}>
      <p
          className="text-center fw-bold"
          style={{
            fontSize: "2.5rem",
            textTransform: "uppercase",
            color: "#333f52",
          }}
        >
          <span
            style={{
              color: "#f7a31c",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            About <span style={{ color: "black" }}> Us</span>
          </span>
        </p>
        <Reveal>
          <p
            style={{
              padding: "1rem",
              textAlign: "justify",
              borderLeft: "1px #f7a31c solid",
              borderRight: "1px #f7a31c solid",
              fontWeight:"bold"
            }}
          >
            Pie Technologies empowers businesses and individuals through a
            diverse landscape of IT solutions. We architect custom software for
            streamlined operations, deliver agility, scalability and innovation
            by reshaping infrastructure for digital transformation, build robust
            cybersecurity shields for data trust, and ensure optimal performance
            with proactive IT management. We seamlessly integrate existing
            technologies, cultivate talent through in-demand training and
            certifications, and accelerate career growth with immersive bootcamp
            experiences. We are your trusted partner in navigating the digital
            landscape, unlocking potential and shaping your success.
          </p>
        </Reveal>
      </Col>

    </Row>
  );
};

export default PortfolioABoutUs;
