import React from "react";
import { Row, Col } from "reactstrap";

import CloudNative from "../../../../images/partner-affiliates/CloudNative.jpg";
import LinuxFoundation from "../../../../images/partner-affiliates/LinuxFoundation.jpg";
import pearson from "../../../../images/partner-affiliates/pearson_ve.png";
import PSEB from "../../../../images/partner-affiliates/pseb-logo-1.webp";
import PASHA from "../../../../images/partner-affiliates/pasha-logo.png";
import pbi from "../../../../images/partner-affiliates/pbi.png";
import navttc from "../../../../images/partner-affiliates/NAVTTC.png";
import mindhub from "../../../../images/partner-affiliates/mindhub.png";
import certiport from "../../../../images/partner-affiliates/certiport.png";

import "./index.css";

const icons = [
  {
    icon: mindhub,
  },
  {
    icon: pearson,
  },
  {
    icon: navttc,
  },
  {
    icon: certiport,
  },
  
  {
    icon: pbi,
  },
  {
    icon: CloudNative,
  },
  {
    icon: LinuxFoundation,
  },
  {
    icon: PSEB,
  },
  {
    icon: PASHA,
  },
];

const PartnerAffiliates = () => {
  return (
    <div className="container-fluid partners" style={{ marginBottom: "8rem" }}>
      <div className="justify-content-center align-items-center flex-column">
        <div>
          <p
            className="text-center fw-bold"
            style={{
              fontSize: "2.5rem",
              textTransform: "uppercase",
              marginTop: "7rem",
              color: "#333f52",
            }}
          >
            Partners / Affiliations
          </p>
        </div>
        <div
          className="partnersIconRow"
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "6rem",
            flexWrap: "wrap",
          }}
        >
          {icons.map((ico, id) => (
            <div
              lg={2}
              md={3}
              sm={4}
              xs={6}
              key={id}
              style={{ flex: "0 0 auto", maxWidth: "calc(50% - 4rem)" }}
            >
              <img
                src={ico.icon}
                alt="loading"
                className="IconsP"
                style={{
                  width: "10rem",
                  height: "10rem",
                  objectFit: "contain",
                  marginTop: "12px",
                  marginBottom: "12px",
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PartnerAffiliates;
