import React from "react";
import { Col, Row } from "reactstrap";
import SlideFromLeft from "../../../components/animations/SlideFromLeft";
import SlideFromRight from "../../../components/animations/SlideFromRight";

const PortfolioServices = () => {
  return (
    <Row>
      <Col
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <h2>
          <span style={{ color: "#f7a31c" , fontSize:"3rem", fontWeight:"bold" }}>Services</span>
        </h2>
      </Col>

      <Col className="Col border mt-3 p-2" lg={4} md={6} sm={12} xs={12}>
        <SlideFromLeft>
          <div >
            <h3>
              SOFTWARE <span style={{ color: "#f7a31c" }}>DEVELOPMENT</span>
            </h3>
            <p>
              Enabling businesses and individuals to create powerful and
              user-friendly software solutions.
            </p>
          </div>
        </SlideFromLeft>
      </Col>
      <Col className="Col border mt-3 p-2" lg={4} md={6} sm={12} xs={12}>
        <SlideFromRight>
          <div >
            <h3>
              <span style={{ color: "#f7a31c" }}>VAPT</span> & CYBER SECURITY
            </h3>
            <p>
              Safeguarding your digital assets with vulnerability assessments
              and penetration testing.
            </p>
          </div>
        </SlideFromRight>
      </Col>
      <Col className="Col border mt-3 p-2" lg={4} md={6} sm={12} xs={12}>
        <SlideFromLeft>
          <div >
            <h3>
              CLOUD & CLOUD NATIVE{" "}
              <span style={{ color: "#f7a31c" }}>COMPUTING</span>
            </h3>
            <p>
              Break free from hardware & unleash your creativity. Scale
              instantly, build modern apps & unlock valuable insights.
            </p>
          </div>
        </SlideFromLeft>
      </Col>
      <Col className="Col border mt-3 p-2" lg={4} md={6} sm={12} xs={12}>
        <SlideFromRight>
          <div >
            <h3>
              <span style={{ color: "#f7a31c" }}>IT MANAGED</span> SERVICES
            </h3>
            <p>Proactive and reliable IT support for optimal performance.</p>
          </div>
        </SlideFromRight>
      </Col>
      <Col className="Col border mt-3 p-2" lg={4} md={6} sm={12} xs={12}>
        <SlideFromLeft>
          <div >
            <h3>
              SYSTEM <span style={{ color: "#f7a31c" }}>INTEGRATIONS</span>
            </h3>
            <p>
              Seamlessly integrating your existing systems to unlock their full
              potential.
            </p>
          </div>
        </SlideFromLeft>
      </Col>
      <Col className="Col border mt-3 p-2" lg={4} md={6} sm={12} xs={12}>
        <SlideFromRight>
          <div >
            <h3>
              <span style={{ color: "#f7a31c" }}>TRAINING</span> &
              CERTIFICATIONS
            </h3>
            <p>
              Equipping your workforce with the in-demand skills they need to
              thrive.
            </p>
          </div>
        </SlideFromRight>
      </Col>

      <Col lg={2} md={0} sm={0} xs={0}>
      </Col>

      <Col className="Col border mt-3 p-2" lg={4} md={6} sm={12} xs={12}>
        <SlideFromLeft>
          <div >
            <h3>
              BOOT CAMP TRAINING{" "}
              <span style={{ color: "#f7a31c" }}>MANAGEMENT</span>
            </h3>
            <p>Expertly managing your bootcamp programs for maximum success.</p>
          </div>
        </SlideFromLeft>
      </Col>
      <Col className="Col border mt-3 p-2" lg={4} md={6} sm={12} xs={12}>
        <SlideFromRight>
          <div >
            <h3>
              HR <span style={{ color: "#f7a31c" }}>CONSULTANCY</span> SERVICES
            </h3>
            <p>
              Optimizing your HR processes for greater efficiency and employee
              satisfaction.
            </p>
          </div>
        </SlideFromRight>
      </Col>

      <Col lg={2} md={0} sm={0} xs={0}>
        </Col>
    </Row>
  );
};

export default PortfolioServices;
