import React from "react";
import "./index.css";
// import ReadMore from "../../../../components/readmore";
import mission from "../../../../images/mission-vision-images/mission.png";
import vision from "../../../../images/mission-vision-images/vision.png";
import about from "../../../../images/mission-vision-images/about.png";
import Reveal from "../../../../components/animations/Reveal";

const data = [
  {
    image: mission,
    title1: "Mission",
    title2: "Statement",
    description: `We believe technology should be a powerful tool for progress, not intimidation. That's why we bridge the gap between talent and opportunity, empowering individuals and businesses to achieve their digital dreams. Pie Technologies is your launchpad to digital success where innovation meets expertise.`,
  },
  {
    image: about,
    title1: "About",
    title2: "Us",
    description: `Pie Technologies empowers businesses and individuals through a diverse landscape of IT solutions. We architect custom software for streamlined operations, deliver agility, scalability, and innovation by reshaping infrastructure for digital transformation, build robust cybersecurity shields for data trust, and ensure optimal performance with proactive IT management. We seamlessly integrate existing technologies, cultivate talent through in-demand training and certifications, and accelerate career growth with immersive bootcamp experiences. We are your trusted partner in navigating the digital landscape, unlocking potential, and shaping your success.`,
  },
  {
    image: vision,
    title1: "Our",
    title2: "Vision",
    description: `Pie Technologies aims to play a vital role in developing an employable workforce by transforming their learnings into skills required by the relevant industry, hence improving the country’s economic growth. Join us, and let's unlock your full potential together.`,
  },
];

const AboutMissionVision = () => {
  return (
    <div
      className="container d-flex justify-content-center align-items-center"
      style={{
        marginBottom: "8rem",
      }}
    >
      <div className="row justify-content-center gap-3">
        {data.map((dat, id) => {
          return (
            <div
              className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2"
              key={id}
            >
              <div className="row bg-light">
                <div
                  className={`col-lg-4 col-md-4 col-sm-12 bg-white d-flex justify-content-center align-items-center ${
                    dat.image === about ? "order-md-2" : ""
                  }`}
                >
                  <div
                    className="image-div"
                    style={{
                      height: "15rem",
                      width: "15rem",
                      overflow: "hidden",
                    }}
                  >
                    <Reveal>
                      <img
                        src={dat.image}
                        alt="bg"
                        style={{ width: "100%", height: "auto" }}
                      />
                    </Reveal>
                  </div>
                </div>
                <div
                  className={`col-lg-8 col-md-8 col-sm-12 ${
                    dat.image === about ? "order-md-1" : ""
                  }`}
                >
                  <div>
                    <Reveal>
                      <div className="d-flex justify-content-center gap-2 mt-3">
                        <p
                          style={{
                            fontSize: "2rem",
                            color: "#333f52",
                            fontWeight: "bold",
                          }}
                        >
                          {dat.title1} {dat.title2}
                        </p>
                      </div>
                      <div
                        className="pt-2 p-3 text-justify"
                        style={{
                          textAlign: "justify",
                        }}
                      >
                        <p className="text-justify">{dat.description}</p>
                      </div>
                    </Reveal>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AboutMissionVision;
