import React from "react";
import Carousel from "react-bootstrap/Carousel";

function ProductCarousel(props) {
  const { image1, image2, image3 } = props;
  return (
    <Carousel
      controls={false}
      indicators={false}
      style={{ margin: "0", padding: "0" }}
    >
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image1}
          alt="First slide"
          style={{ height: "20rem", objectFit: 'contain' }}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image2}
          alt="Second slide"
          style={{ height: "20rem", objectFit: "contain" }}
        />
      </Carousel.Item>
      {image3 && (
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={image3}
            alt="Third slide"
            style={{ height: "20rem", objectFit: "contain" }}
          />
        </Carousel.Item>
      )}
    </Carousel>
  );
}

export default ProductCarousel;
