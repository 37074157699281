import React from "react";

import SlickCarousel from "./slick-carousel";

const PartnerOrganizations = () => {
  return (
    <div className="my-6">
      <h3
        className="text-center fw-bold"
        style={{
          fontSize: "2.5rem",
          textTransform: "uppercase",
          color: "#333f52",
          marginBottom: "2rem",
          marginTop: "5rem",
        }}
      >
        Partner Organizations
      </h3>
      <SlickCarousel />
    </div>
  );
};

export default PartnerOrganizations;
