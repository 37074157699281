import React from "react";
import icon1 from "../../../../images/services-expertise/react.png";
import icon2 from "../../../../images/services-expertise/Mobile.png";
import icon4 from "../../../../images/services-expertise/aspdot.png";
import icon5 from "../../../../images/services-expertise/blockchain.png";
import icon6 from "../../../../images/services-expertise/c-sharp.png";
import icon7 from "../../../../images/services-expertise/flutter.png";
import icon8 from "../../../../images/services-expertise/global-network.png";
import icon9 from "../../../../images/services-expertise/java.png";
import icon10 from "../../../../images/services-expertise/mongodb.png";
import icon11 from "../../../../images/services-expertise/node.png";
import icon12 from "../../../../images/services-expertise/php.png";
import icon13 from "../../../../images/services-expertise/python.png";
import icon14 from "../../../../images/services-expertise/quality-assurance.png";
import icon15 from "../../../../images/services-expertise/seo-search-symbol.png";
import icon16 from "../../../../images/services-expertise/sql-server.png";
import icon17 from "../../../../images/services-expertise/AWS.png";
import icon18 from "../../../../images/services-expertise/Azure.png";
import icon19 from "../../../../images/services-expertise/GCP.png";
import icon20 from "../../../../images/services-expertise/Docker.png";
import icon21 from "../../../../images/services-expertise/Kubernetes.png";
const icons = [
  {
    icon: icon1,
  },
  {
    icon: icon2,
  },
  {
    icon: icon17,
  },
  {
    icon: icon18,
  },
  {
    icon: icon19,
  },
  {
    icon: icon20,
  },
  {
    icon: icon21,
  },
  {
    icon: icon4,
  },
  {
    icon: icon5,
  },
  {
    icon: icon6,
  },
  {
    icon: icon7,
  },
  {
    icon: icon8,
  },
  {
    icon: icon9,
  },
  {
    icon: icon10,
  },
  {
    icon: icon11,
  },
  {
    icon: icon12,
  },
  {
    icon: icon13,
  },
  {
    icon: icon14,
  },
  {
    icon: icon15,
  },
  {
    icon: icon16,
  },
];

const OurExpertise = () => {
  return (
    <div
      className="bg-light"
      style={{ paddingBottom: "4rem", paddingTop: "1rem" }}
    >
      <p
        className="text-center fw-bold"
        style={{
          fontSize: "2.5rem",
          textTransform: "uppercase",
          color: "#333f52",
          marginBottom: "2rem",
          marginTop: "5rem",
        }}
      >
        Our Expertise
      </p>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          className="container row justify-content-center align-items-center mb-5 mt-3 gap-4"
          style={{
            maxWidth: "90%",
            borderRadius: "5px",
          }}
        >
          {icons.map((ico, id) => {
            return (
              <div
                key={id}
                className="col-md-4 col-lg-2 col-sm-6 d-flex justify-content-center align-items-center my-3"
              >
                <div className="iconX">
                  <img
                    src={ico.icon}
                    alt="load"
                    style={{
                      height: "145px",
                      width: "145px",
                      transition: "transform 0.2s",
                    }}
                    className="hover-grow"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OurExpertise;
