import React from "react";
import { IoLocation } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { Row, Col } from "reactstrap";
import ContactForm from "../../components/contact-form";
import background from "../../images/backgrounds/subtle-prism.svg";
import "./index.css";

const contactCard = [
  { logo: MdEmail, text1: "info@pie-technologies.com" },

  { logo: FaPhoneAlt, text1: "+92 51 614 2400", text2: "+92 333 502 0126" },
  {
    logo: IoLocation,
    text1: "plot no 38/129, Jinnah Garden, Phase-1, Islamabad.",
    text2:
      "Buraq Centre, 2nd Floor, 11-D, 6th Road, Satellite Town, Rawalpindi",
  },
];

const ContactUs = () => {
  return (
    <>
      <div
        style={{
          flexDirection: "column",
          alignItems: "center",
          display: "flex",
          marginTop: "6.1rem",
          marginBottom: "3rem",
        }}
      >
        <h1
          style={{
            color: "#333f52",
            marginTop: "2rem",
            fontWeight: "bolder",
          }}
        >
          Contact Us
        </h1>
        <Row
          style={{
            width: "100%",
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
            backgroundColor: "rgba(249, 198, 141, 0.8)",
            backgroundPosition: "center",
            boxShadow: "inset 0 0 10px 10px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Col
            lg={6}
            md={6}
            sm={12}
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                marginTop: "3.5rem",
                marginBottom: "5rem",
                backgroundColor: "#333f52",
                color: "white",
                padding: "4rem",
                borderRadius: "20px",
                width: "35rem",
              }}
            >
              <ContactForm />
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12} style={{ marginTop: "3.5rem" }}>
            {contactCard.map((item, id) => {
              return (
                <div
                  style={{
                    backgroundColor: "#333f52",
                    borderRadius: "20px",
                    color: "white",
                    minHeight: "8.5rem",
                    marginBottom: "1.3rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                  key={id}
                >
                  <item.logo
                    style={{
                      color: "#f7a31c",
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                      marginLeft: "1rem",
                    }}
                  />
                  <div style={{ marginLeft: "1rem", marginTop: "1.2rem" }}>
                    {" "}
                    <p style={{ margin: 0 }}>{item.text1}</p> <br />
                    <p style={{ margin: 0 }}>{item.text2}</p>
                  </div>
                </div>
              );
            })}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ContactUs;
