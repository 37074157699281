import React from "react";
import { Button, Col, Row } from "reactstrap";

const MoreABoutUs = () => {
  return (
    
      <Row style={{marginTop:"3rem", marginBottom:"3rem"}}>
        <Col lg={3} md={2} sm={0} xs={0}>
        </Col>
        <Col
          lg={4}
          md={4}
          sm={12}
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <p
          className="text-center fw-bold"
          style={{
            fontSize: "2.5rem",
            textTransform: "uppercase",
            color: "#333f52",
          }}
        >
            <span style={{ color: "black" }}>More About</span>
            <span style={{ color: "#f7a31c" }}> Us</span>
          </p>
        </Col>
        <Col
          lg={1}
          md={2}
          sm={6}
          xs={6}
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Button style={{ backgroundColor: "#f7a31c", border: "none", maxHeight:"3rem" }}>
            Preview
          </Button>
        </Col>
        <Col
          lg={1}
          md={2}
          sm={6}
          xs={6}
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Button style={{ backgroundColor: "#f7a31c", border: "none", maxHeight:"3rem" }}>
            Download
          </Button>
        </Col>
        <Col lg={3} md={2} sm={0} xs={0}>
        
        </Col>
      </Row>
  );
};

export default MoreABoutUs;
