import React from "react";
// import { FaReact, FaMobileAlt, FaPython, FaJava, FaPhp } from "react-icons/fa";
// import { SiCsharp, SiHiveBlockchain } from "react-icons/si";
// import { CgWebsite } from "react-icons/cg";
// import { FcEngineering } from "react-icons/fc";
// import { MdSecurity, MdHighQuality } from "react-icons/md";
// import { TbCloudComputing } from "react-icons/tb";
// import { DiScrum } from "react-icons/di";
// import { BiData } from "react-icons/bi";
// import { TbSeo } from "react-icons/tb";
// import { AiOutlineDesktop } from "react-icons/ai";
// import { SiFiverr } from "react-icons/si";
// import { GiNetworkBars } from "react-icons/gi";
// import { MdOutlineManageAccounts } from "react-icons/md";
import bigData from "./TOP learning Ctgry/Big-data.png";
import blockChain from "./TOP learning Ctgry/BlockchainLogo.png";
import cloudComputing from "./TOP learning Ctgry/cloud_computing.png";
import cSharp from "./TOP learning Ctgry/c-sharp.png";
import cyberLogo from "./TOP learning Ctgry/CyberLogo.png";
import dataScience from "./TOP learning Ctgry/data-science.png";
import fiverr from "./TOP learning Ctgry/Fiverr.png";
import graphicsDesign from "./TOP learning Ctgry/GraphicLogo.png";
import javaLogo from "./TOP learning Ctgry/java_Icon.png";
import appDevelop from "./TOP learning Ctgry/Android.png";
import phpLogo from "./TOP learning Ctgry/php.png";
import powerBiLogo from "./TOP learning Ctgry/power_bi.png";
import projectManage from "./TOP learning Ctgry/Project_Management.png";
import pythonLogo from "./TOP learning Ctgry/python.png";
import qaLogo from "./TOP learning Ctgry/QAlogo.png";
import reactLogo from "./TOP learning Ctgry/react.png";
import scrumLogo from "./TOP learning Ctgry/Scrumlogo.png";
import seoLogo from "./TOP learning Ctgry/SEOlogo.png";
import figmaLogo from "./TOP learning Ctgry/Figma.png";

import "./index.css";
import { Button } from "reactstrap";

const data = [
  {
    text: "Blockchain",
    icon: blockChain,
  },
  {
    text: "Project Management",
    icon: projectManage,
  },
  {
    text: "Python & Machine Learning",
    icon: pythonLogo,
  },
  {
    text: "Big Data Analytics",
    icon: bigData,
  },
  {
    text: "VPAT & Cyber Security",
    icon: cyberLogo,
  },
  {
    text: (
      <span style={{ marginBottom: "-1.5rem" }}>Digital Marketing & SEO</span>
    ),
    icon: seoLogo,
  },
  {
    text: (
      <span style={{ marginBottom: "-1.5rem" }}>
        Graphics Designing & Video Editing
      </span>
    ),
    icon: graphicsDesign,
  },
  {
    text: <span style={{ marginBottom: "-1.5rem" }}>Fiverr & Upwork</span>,
    icon: fiverr,
  },
  {
    text: <span style={{ marginBottom: "-1.5rem" }}>Mern Stack</span>,
    icon: reactLogo,
  },
  {
    text: <span style={{ marginBottom: "-1.5rem" }}>App Development</span>,
    icon: appDevelop,
  },

  {
    text: "Java Programming",
    icon: javaLogo,
  },
  {
    text: "PHP (Codeigniter / Laravel)",
    icon: phpLogo,
  },
  {
    text: "C#, ASP.NET MVC",
    icon: cSharp,
  },
  {
    text: "Web Design",
    icon: figmaLogo,
  },
  {
    text: "Data Sciences, ML & AI",
    icon: dataScience,
  },

  {
    text: "Cloud Computing",
    icon: cloudComputing,
  },
  {
    text: "Software Quality Assurance",
    icon: qaLogo,
  },
  {
    text: "Scrum Frameworks",
    icon: scrumLogo,
  },

  {
    text: "Power Bi",
    icon: powerBiLogo,
  },
];
const LearningCategories = () => {
  return (
    <div className="TopLC bg-light">
      <div
        className="d-flex"
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          paddingBottom: "5rem",
        }}
      >
        <p
          className="text-center fw-bold"
          style={{
            fontSize: "2.5rem",
            textTransform: "uppercase",
            color: "#333f52",
            marginBottom: "2rem",
            marginTop: "5rem",
          }}
        >
          Top Learning Categories
        </p>
        <div className="container row d-flex justify-content-center">
          {data.map((dat, id) => {
            return (
              <div
                key={id}
                className="col-4"
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "9rem",
                  width: "16rem",
                }}
              >
                <Button id="button">
                  <div
                    className="image-container"
                    style={{ height: "4rem", width: "6rem" }}
                  >
                    <img
                      src={dat.icon}
                      alt="icon"
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  </div>
                  <p style={{ color: "grey" }}>{dat.text}</p>
                </Button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LearningCategories;
