// ImagePopup.js
import React from "react";
import image from "../../images/popup-24May24.jpg";

const customStyles = {
  container: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  closeButton: {
    position: "absolute",
    top: "90px",
    right: "190px",
    cursor: "pointer",
    fontSize: "3yyy0px",
    color: "white",
    backgroundColor: "black",
  },
  modalImage: {
    maxWidth: "70%",
    maxHeight: "70%",
    marginTop: "3rem",
  },
};

const ImagePopup = ({ isOpen, closeModal }) => {
  if (!isOpen) {
    return null;
  }
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  return (
    <div style={customStyles.container} onClick={handleOverlayClick}>
      <div style={customStyles.closeButton} onClick={closeModal}>
        <span role="img" aria-label="Close">
          ×
        </span>
      </div>
      <img src={image} alt="Popup" style={customStyles.modalImage} />
    </div>
  );
};

export default ImagePopup;
