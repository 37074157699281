import React from "react";
import logo from "../../images/whitePieTechLogo.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YoutubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import "./index.css";
import { IconButton, Link } from "@mui/material";

const logoStyle = {
  width: "300px",
  height: "auto",
};

const Footer = () => {
  let address = "Plot no 38/129, Jinnah Garden, Phase-1, Islamabad.";
  let address2 =
    "Buraq Centre, 2nd Floor, 11-D, 6th Road, Satellite Town, Rawalpindi";
  let mobile = "+92 333 5020126";
  let phone = "+92 51 6142400";
  let email = "info@pie-technologies.com";
  return (
    <footer
      className="footerBackground py-3 d-flex justify-content-center align-items-center"
      style={{
        height: "60%",
      }}
    >
      <div className="row container d-flex justify-content-center align-items-center text-light">
        <div className="col-lg-4 col-md-12 col-sm-12 text-center ">
          <div className="row container">
            <div className="col-lg-12 " style={{ marginBottom: "1rem" }}>
              <img src={logo} style={logoStyle} alt="logo of sitemark" />
            </div>
            <div className="col-lg-12">
              <p>
                Your launchpad to digital success, Where innovation meets
                expertise.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-8">
          <div className="row">
            <div
              className="col-lg-4 col-md-12 col-sm-12 text-center d-flex gap-2 mt-4"
              style={{
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <h2>Quick Links</h2>
              <Link className="theLink" href="/">
                Home
              </Link>
              <Link className="theLink" href="/services">
                Services
              </Link>
              <Link className="theLink" href="/training">
                Training
              </Link>
              <Link className="theLink" href="/products">
                Products
              </Link>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12 mt-4">
              <div className="row">
                <div className="col-lg-12 text-center mb-3">
                  <h2>Contact Us</h2>
                </div>
                <div
                  className="col-lg-6 d-flex"
                  style={{
                    flexDirection: "column",
                    alignItems: "start",
                    borderRight: "1px solid white",
                  }}
                >
                  <p>{address}</p>
                  <p>{address2}</p>
                </div>
                <div
                  className="col-lg-6 d-flex"
                  style={{
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <p>{email}</p>
                  <p>{mobile}</p>
                  <p>{phone}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 ">
          <hr className="mt-4" />
          <div className="row">
            <div
              className="col-sm-12 col-lg-6 col-md-6 d-flex justify-content-start p-0"
              style={{ alignItems: "center" }}
            >
              <p style={{ fontWeight: "600", fontSize: "90%" }}>
                © 2022 Pie Technologies. All Rights Reserved.
              </p>
            </div>
            <div className="col-sm-12 col-lg-6 col-md-6 d-flex justify-content-end align-items-center">
              <IconButton
                color="inherit"
                href="https://www.facebook.com/people/Pie-Technologies/61550787481498/"
                aria-label="facebook"
                sx={{ alignSelf: "center" }}
              >
                <FacebookIcon style={{ height: "2rem", width: "2rem" }} />
              </IconButton>

              <IconButton
                color="inherit"
                href="https://pk.linkedin.com/company/pie-technologies-pvt-ltd"
                aria-label="LinkedIn"
                sx={{ alignSelf: "center" }}
              >
                <LinkedInIcon style={{ height: "2rem", width: "2rem" }} />
              </IconButton>

              <IconButton
                color="inherit"
                href="https://www.instagram.com/pietechnologies/"
                aria-label="LinkedIn"
                sx={{ alignSelf: "center" }}
              >
                <InstagramIcon style={{ height: "2rem", width: "2rem" }} />
              </IconButton>

              <IconButton
                color="inherit"
                href="https://www.youtube.com/@pie-technologies"
                aria-label="LinkedIn"
                sx={{ alignSelf: "center" }}
              >
                <YoutubeIcon style={{ height: "2rem", width: "2rem" }} />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
