import React from "react";
import { FaExternalLinkAlt } from "react-icons/fa";

const ApplyButtonMain = () => {
  const handleClick = () => {
    window.location.href = "http://pie-form.vercel.app";
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "200px",
        right: "10px",
        zIndex: 10,
        cursor: "pointer",
        backgroundColor: "orange",
        padding: "15px",
        borderRadius: "5px",
        color: "white",
        display: "flex",
        alignItems: "center",
      }}
      onClick={handleClick}
    >
      <span
        style={{ fontWeight: "bold", fontSize: "1.2em", marginRight: "5px" }}
      >
        Apply Here
      </span>
      <FaExternalLinkAlt
        style={{
          marginLeft: "5px",
        }}
      />
    </div>
  );
};

export default ApplyButtonMain;
