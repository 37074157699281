import React from "react";

import "animate.css";
import PortfolioServices from "./_components/portfolio-services";
import MoreABoutUs from "./_components/more-about-us";
import PortfolioABoutUs from "./_components/portfolio-about-us";
import IntroVideo from "./_components/introVideo";
import ContactUsPortfolio from "./_components/contact-us";
import background from "../../images/backgrounds/bg-curve.svg";
import background2 from "../../images/backgrounds/subtle-stripes.svg";

const Portfolio = () => {
  return (
    <div style={{ marginTop: "6.1rem", paddingBottom: "0" }}>
      <IntroVideo />
      <PortfolioABoutUs />

      <div
        style={{
          backgroundImage: `url(${background2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "1rem",
        }}
      >
        <PortfolioServices />
      </div>

      <MoreABoutUs />

      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundColor: "rgba(249, 198, 141, 0.8)",
          backgroundPosition: "center",
        }}
      >
        <ContactUsPortfolio />
      </div>
    </div>
  );
};

export default Portfolio;
