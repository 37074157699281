import React from "react";
import { Row, Col } from "reactstrap";

import { AiOutlineBook } from "react-icons/ai";
import { BsPersonWorkspace } from "react-icons/bs";
import { BsPersonPlus } from "react-icons/bs";
import { BsPersonVcardFill } from "react-icons/bs";
import { TbFileCertificate } from "react-icons/tb";
import { SiJsonwebtokens } from "react-icons/si";
import backImage from "../../../../images/backgrounds/page-turner.png";

import "./index.css";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const data = [
  {
    title: "Courses",
    icon: AiOutlineBook,
    number: "100",
    color: "#333f52",
  },
  {
    title: "Trainers",
    icon: BsPersonWorkspace,
    number: "50",
    color: "#f7a31c",
  },
  {
    title: "Trainees",
    icon: BsPersonPlus,
    number: "1000",
    color: "#333f52",
  },
  {
    title: "Current Trainees",
    icon: BsPersonVcardFill,
    number: "100",
    color: "#f7a31c",
  },
  {
    title: "Trainings",
    icon: TbFileCertificate,
    number: "25",
    color: "#333f52",
  },
  {
    title: "Domains",
    icon: SiJsonwebtokens,
    number: "20",
    color: "#f7a31c",
  },
];

const Statistics = () => {
  return (
    <div
      className="main"
      style={{
        width: "100%",
        backgroundImage: `url(${backImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPositionX: "left",
      }}
    >
      <div className="container">
        <Row className="text-center text-light">
          {data.map((dat, id) => {
            return (
              <Col
                lg={2}
                xs="12"
                md="4"
                key={id}
                className="mx-0 StatsCol"
                style={{ padding: 0 }}
              >
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "30vh" }}
                >
                  <Row className="align-items-center justify-content-center titleCol">
                    <Col xs="12">
                      <p
                        style={{
                          fontSize: "3rem",
                          fontWeight: "600",
                          color: "#333f52",
                        }}
                      >
                        <VisibilitySensor
                          partialVisibility
                          offset={{ bottom: 200 }}
                        >
                          {({ isVisible }) => (
                            <div
                              className="text-center"
                              style={{ height: 100 }}
                            >
                              {isVisible && (
                                <CountUp end={dat.number} duration={4} />
                              )}
                              {isVisible && (
                                <span style={{ fontSize: "2rem" }}>+</span>
                              )}
                            </div>
                          )}
                        </VisibilitySensor>
                      </p>
                    </Col>
                    <Col xs="12">
                      <p
                        style={{
                          fontSize: "18px",
                          color: "#333f52",
                          fontWeight: "600",
                        }}
                      >
                        {dat.title}
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};

export default Statistics;
