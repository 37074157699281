import React from "react";
import { Col, Row } from "reactstrap";
import ContactForm from "../../../components/contact-form";

const ContactUsPortfolio = () => {
  return (
    <Row
      style={{
        paddingTop: "3rem",
        paddingBottom: "3rem",
      }}
    >
      <Col
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <p
          className="text-center fw-bold"
          style={{
            fontSize: "2.5rem",
            textTransform: "uppercase",
            color: "#333f52",
            marginBottom: "2rem",
            marginTop: "5rem",
          }}
        >
          {/* <span style={{ color: "#f7a31c" }}>Contact</span> */}
          Contact
          <span style={{ color: "black" }}> Us</span>
        </p>
      </Col>
      <Col
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <div
          style={{
            width: "38rem",
            padding: "1rem",
          }}
        >
          <ContactForm />
        </div>
      </Col>
    </Row>
  );
};

export default ContactUsPortfolio;
