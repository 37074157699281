import React, { useState } from "react";
import atlms1 from "../../images/products-screenshots/Atlms/ATLMRS Dash.png";
import atlms2 from "../../images/products-screenshots/Atlms/ATLMRS Trainer Dash.png";
import atlms3 from "../../images/products-screenshots/Atlms/ATLMRS Student Dash.png";

import lms1 from "../../images/products-screenshots/Atlms/LMS Admin Dash.png";
import lms2 from "../../images/products-screenshots/Atlms/LMS Trainer Dash.png";
import lms3 from "../../images/products-screenshots/Atlms//LMS Trainee Dasg.png";

import EAS1 from "../../images/products-screenshots/Weather_app/weather-app-1.png";
import EAS2 from "../../images/products-screenshots/Weather_app/weather-app-2.png";

import ClassEquity1 from "../../images/products-screenshots/CRM/crm-1.png";
import ClassEquity2 from "../../images/products-screenshots/CRM/crm-2.jpeg";

import A1telecom1 from "../../images/products-screenshots/A1telecom/a1-1.png";
import A1telecom2 from "../../images/products-screenshots/A1telecom/a1-2.png";



import { Row, Col, Button } from "react-bootstrap";

import "./index.css";
import ProductCarousel from "./_components/carousel";
import AtlmsDetails from "./_components/atlms-detail";

const data1 = {
  title: "PieTech Boots-ATLMS (Pie-Tech Admission Testing Learning Management System)",
  data: "",
  desc: (
    <span style={{ fontSize: "14px" }}>
      Specific for bootcamps and self paced training programs. A complete
      Learning Management System which is a complete solution for the trainings
      boot camps which involves the online registrations, Qualifications test,
      enrollments, attendance, examinations, evaluation and results, training
      materials, feedbacks, lecture recordings etc. Different dashboards for
      admin, monitoring, trainers and trainees.
    </span>
  ),
};

const data2 = {
  title: "PieTech-LMS (Pie-Tech Learning Management System)",
  desc: `School Management Software is the system design for educational institutions that contains the set of instruction like managing teachers, classes, timetable and fee etc. These instructions were previously performed manually.`,
};

const data3 = {
  title: "Emergency Alert System",
  desc: `Emergency Alert System with a user-friendly mobile app for quick notifications and a convenient web app for real-time monitoring. Stay informed and safe with alerts for various emergencies, while our web app provides comprehensive oversight from any browser. Trust in our system for peace of mind during critical situations.`,
};

const data4 = {
  title: "ClassEquity",
  desc: `Class equity teacher is a platform where teacher can give virtual money online their students by assigning classroom jobs. Also, teacher can give bonuses, fines, expenses and rent/utilities to their students`,
};

const data5 = {
  title: "A1 Telecomms",
  desc: `We provided A1-Telecoms with a comprehensive online solution, crafting a professional website that highlights their services and boosts their digital presence. Our tailored design and functionality ensure seamless user engagement, driving business growth and customer satisfaction.`,
};

const Products = () => {
  //for modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <div style={{ marginTop: "6.1rem" }}>
        <Row className="justify-content-center align-items-center">
          <Col
            xs={0}
            sm={1}
            md={1}
            lg={1}
            className="d-flex justify-content-center"
          >
            {/* <h1 style={{ color: "#f7a31c", marginTop: "2rem", fontWeight: "bolder" }}>Our Products</h1> */}
          </Col>
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={10}
            className="d-flex justify-content-center"
          >
            <h1
              style={{
                color: "#333f52",
                marginTop: "2rem",
                fontWeight: "bolder",
              }}
            >
              Our Products
            </h1>
          </Col>
          <Col
            xs={1}
            sm={1}
            md={1}
            lg={1}
            className="SearchCol d-flex justify-content-end"
          ></Col>
        </Row>
      </div>
      <div
        className="container-fluid d-flex justify-content-center align-items-center"
        style={{
          margin: 0,
          padding: 0,
          flexDirection: "column",
        }}
      >
        {data1 && (
          <Row
            className="justify-content-center my-5"
            style={{ width: "100vw", margin: 0, padding: 0 }}
          >
            <Col md="10" lg="10" className=" bg-light shadow">
              <Row
                style={{
                  borderRadius: "5px",
                }}
              >
                <Col
                  md="6"
                  className="d-flex justify-content-center align-items-center flex-column p-2"
                >
                  <p style={{ fontSize: "1.3rem", fontWeight: "bold", textAlign: "center", }}>
                    {data1.title}
                  </p>
                  <p className="container" style={{ textAlign: "center" }}>
                    {data1.desc}
                  </p>
                  <div className="text-center">
                    {/* <Button
                      style={{
                        backgroundColor: "#f7a31c",
                        marginRight: "10px",
                      }}
                      onClick={() => setIsModalOpen(true)}y
                    >
                      View Price Plans
                    </Button> */}
                    <a
                      href="http://atlms.pie-technologies.com"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <Button
                        style={{ backgroundColor: "#f7a31c" }}
                        onClick={() => {}}
                      >
                        View ATLMS
                      </Button>
                    </a>
                  </div>
                </Col>

                <Col md="6" style={{ margin: 0, padding: 0 }}>
                  <ProductCarousel image1={atlms1} image2={atlms2} image3={atlms3} />
                </Col>
                {/* <Col className="mb-4">
                  
                </Col> */}
              </Row>
            </Col>
          </Row>
        )}

        {data2 && (
          <Row
            className="justify-content-center my-5"
            style={{ width: "100vw", margin: 0, padding: 0 }}
          >
            <Col md="10" lg="10" className=" bg-light shadow">
              <Row
                style={{
                  borderRadius: "5px",
                }}
              >
                <Col
                  md="6"
                  className="d-flex justify-content-center align-items-center flex-column"
                >
                  <p
                    style={{
                      fontSize: "1.2rem",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {data2.title}
                  </p>
                  <p className="container" style={{ textAlign: "center" }}>
                    {data2.desc}
                  </p>
                </Col>
                <Col md="6" style={{ margin: 0, padding: 0 }}>
                  <ProductCarousel image1={lms1} image2={lms2} image3={lms3} />
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {data3 && (
          <Row
            className="justify-content-center my-5"
            style={{ width: "100vw", margin: 0, padding: 0 }}
          >
            <Col md="10" lg="10" className=" bg-light shadow">
              <Row
                style={{
                  borderRadius: "5px",
                }}
              >
                <Col
                  md="6"
                  className="d-flex justify-content-center align-items-center flex-column"
                >
                  <p
                    style={{
                      fontSize: "1.2rem",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {data3.title}
                  </p>
                  <p className="container" style={{ textAlign: "center" }}>
                    {data3.desc}
                  </p>
                </Col>
                <Col md="6" style={{ margin: 0, padding: 0 }}>
                  <ProductCarousel image1={EAS1} image2={EAS2}  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {data4 && (
          <Row
            className="justify-content-center my-5"
            style={{ width: "100vw", margin: 0, padding: 0 }}
          >
            <Col md="10" lg="10" className=" bg-light shadow">
              <Row
                style={{
                  borderRadius: "5px",
                }}
              >
                <Col
                  md="6"
                  className="d-flex justify-content-center align-items-center flex-column"
                >
                  <p
                    style={{
                      fontSize: "1.2rem",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {data4.title}
                  </p>
                  <p className="container" style={{ textAlign: "center" }}>
                    {data4.desc}
                  </p>
                </Col>
                <Col md="6" style={{ margin: 0, padding: 0 }}>
                  <ProductCarousel image1={ClassEquity1} image2={ClassEquity2}/>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {data5 && (
          <Row
            className="justify-content-center my-5"
            style={{ width: "100vw", margin: 0, padding: 0 }}
          >
            <Col md="10" lg="10" className=" bg-light shadow">
              <Row
                style={{
                  borderRadius: "5px",
                }}
              >
                <Col
                  md="6"
                  className="d-flex justify-content-center align-items-center flex-column"
                >
                  <p
                    style={{
                      fontSize: "1.2rem",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {data5.title}
                  </p>
                  <p className="container" style={{ textAlign: "center" }}>
                    {data5.desc}
                  </p>
                </Col>
                <Col md="6" style={{ margin: 0, padding: 0 }}>
                  <ProductCarousel image1={A1telecom1} image2={A1telecom2} />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>

      <AtlmsDetails isModalOpen={isModalOpen} toggleModal={toggleModal} />
    </>
  );
};

export default Products;
