import React, { useState } from "react";
import { Form, Formik } from "formik";
import { MdFrontLoader } from "react-icons/md";
import { ContactFormSchema } from "./validation";
import { Button, Input, Label, Modal, ModalBody } from "reactstrap";
import toast from "react-hot-toast";
import { FcApproval } from "react-icons/fc";

const ContactForm = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleContact = async (values, setSubmitting) => {
    try {
      const data = {
        service_id: "service_fvaal9i",
        template_id: "template_mcb1f0r",
        user_id: "srdjEUSDO1jilpxnA",
        template_params: values,
      };
  
      const response = await fetch("https://api.emailjs.com/api/v1.0/email/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
  
      if (response.ok) {
        toast.success("Message Sent Successfully");
        setOpenModal(true);
      } else {
        const errorMessage = await response.text();
        toast.error(`Error: ${errorMessage}`);
      }
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        centered
        isOpen={openModal}
        toggle={() => setOpenModal(!openModal)}
      >
        <ModalBody
          style={{
            backgroundColor: "#d0d3d6",
            border: "1px orange solid",
            borderRadius: "4px",
          }}
        >
          <div className="d-flex justify-content-center">
            <FcApproval size={70} />
          </div>
          <h2 className="d-flex justify-content-center">
            {" "}
            Thank you for reaching out!{" "}
          </h2>
          <p>
            We appreciate your interest and will get back to you as soon as
            possible.
          </p>
          <div className="d-flex justify-content-center">
            <Button color="success" onClick={() => setOpenModal(!openModal)}>
              Close
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <Formik
        initialValues={{
          name: "",
          email: "",
          message: "",
        }}
        validationSchema={ContactFormSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleContact(values, setSubmitting);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div>
              <Label style={{ fontWeight: "bold" }}>Name</Label>
              <Input
                placeholder="Name.."
                name="name"
                type="text"
                defaultValue={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.name && touched.name && (
                <p style={{ color: "red" }}>{errors.name}</p>
              )}
            </div>
            <div>
              <Label style={{ fontWeight: "bold" }}>Email</Label>
              <Input
                placeholder="Email.."
                name="email"
                type="text"
                defaultValue={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.email && touched.email && (
                <p style={{ color: "red" }}>{errors.email}</p>
              )}
            </div>
            <div>
              <Label style={{ fontWeight: "bold" }}>Message</Label>
              <Input
                placeholder="Enter Your Message"
                name="message"
                type="textarea"
                defaultValue={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.message && touched.message && (
                <p style={{ color: "red" }}>{errors.message}</p>
              )}
            </div>

            <div className="mt-4 space-x-3">
              <Button disabled={isSubmitting} type="submit">
                {isSubmitting && (
                  <MdFrontLoader className="mr-2 h-4 w-4 animate-spin" />
                )}
                Send Message
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ContactForm;
