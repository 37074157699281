import React, { useState } from "react";
import logoPic from "../../images/nav-logo/Logo-tag.png";
import {
  Navbar,
  NavbarBrand,
  Nav,
  DropdownMenu,
  DropdownItem,
  NavbarToggler,
  DropdownToggle,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Dropdown,
} from "reactstrap";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import "./index.css";

const MainNavbar = ({ direction, ...args }) => {
  const token = localStorage.getItem("token");
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const toggleDrop1 = () => setDropdownOpen1((prevState) => !prevState);

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const handleNavbarLinkClick = () => {
    document.getElementById("trainingLink").classList.remove("active");
  };
  return (
    <div style={{ margin: 0, padding: 0, overflowX: "hidden" }}>
      <Navbar
        className="fixed-top bg-light d"
        expand="lg"
        style={{
          margin: 0,
          padding: 0,
          borderBottom: "3px solid #d3d3d3",
          borderBottomWidth: "2px",
        }}
      >
        <NavbarBrand className="NavBr" style={{ marginLeft: "12vh" }}>
          <img src={logoPic} width="100%" height="85rem" alt="" />
        </NavbarBrand>
        <Nav className="Xyz gap-5" style={{ alignItems: "center" }} navbar>
          <NavLink
            onClick={handleNavbarLinkClick}
            to={"/"}
            className="nav-link"
            activeclassname="active"
          >
            Home
          </NavLink>
          <NavLink
            onClick={handleNavbarLinkClick}
            to={"/services"}
            className="nav-link"
            activeclassname="active"
          >
            Services
          </NavLink>
          <Dropdown
            className="this-drop"
            isOpen={dropdownOpen1}
            toggle={toggleDrop1}
            direction={direction}
          >
            <DropdownToggle
              style={{
                border: "none",
                background: "none",
                padding: "0px",
              }}
            >
              <NavLink className="nav-link-drop" id="trainingLink">
                Training
              </NavLink>
            </DropdownToggle>
            <DropdownMenu {...args}>
              <DropdownItem
                className="nav-link-drop-item"
                onClick={() => {
                  navigate("/training");
                  document
                    .getElementById("trainingLink")
                    .classList.add("active");
                }}
              >
                Trainings
              </DropdownItem>
              <DropdownItem
                className="nav-link-drop-item"
                onClick={() => {
                  navigate("/pearson");
                  document
                    .getElementById("trainingLink")
                    .classList.add("active");
                }}
              >
                Certifications
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>

          <NavLink
            onClick={handleNavbarLinkClick}
            to={"/products"}
            className="nav-link"
            activeclassname="active"
          >
            Products
          </NavLink>
          {token ? (
            <>
              <Dropdown
                isOpen={dropdownOpen1}
                toggle={toggleDrop1}
                direction={direction}
              >
                <DropdownToggle
                  style={{
                    border: "none",
                    background: "none",
                    padding: "0px",
                  }}
                >
                  <NavLink className="nav-link">Careers</NavLink>
                </DropdownToggle>
                <DropdownMenu {...args}>
                  <DropdownItem
                    onClick={() => {
                      navigate("/trainercareer");
                    }}
                  >
                    Register as Trainer
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      navigate("/developercareer");
                    }}
                  >
                    Register as Developer
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </>
          ) : (
            <></>
          )}
          <NavLink
            onClick={handleNavbarLinkClick}
            to={"/contact"}
            className="nav-link"
            activeclassname="active"
          >
            Contact Us
          </NavLink>
          <NavLink
            onClick={handleNavbarLinkClick}
            to={"https://pie-profile.vercel.app/"}
            style={{ textDecoration: "none" }}
            activeclassname="active"
          >
            <button className="button-87">Portfolio</button>
          </NavLink>
        </Nav>

        <NavbarToggler onClick={toggle} />
        <Offcanvas placement="end" isOpen={isOpen} toggle={toggle}>
          <OffcanvasHeader>
            <NavbarToggler onClick={toggle} />
            <img src={logoPic} width="100%" height="80" alt="" />
          </OffcanvasHeader>
          <OffcanvasBody>
            <Nav className="flex-column">
              <NavLink
                to={"/"}
                className="nav-link-off-canvas"
                activeclassname="active"
                onClick={toggle}
              >
                Home
              </NavLink>

              <NavLink
                to={"/services"}
                className="nav-link-off-canvas"
                activeclassname="active"
                onClick={toggle}
              >
                Services
              </NavLink>
              <NavLink
                to={"/training"}
                className="nav-link-off-canvas"
                activeclassname="active"
                onClick={toggle}
              >
                Trainings
              </NavLink>
              <NavLink
                to={"/pearson"}
                className="nav-link-off-canvas"
                activeclassname="active"
                onClick={toggle}
              >
                Certifications
              </NavLink>
              <NavLink
                to={"/products"}
                className="nav-link-off-canvas"
                activeclassname="active"
                onClick={toggle}
              >
                Products
              </NavLink>
              {token ? (
                <NavLink
                  to={"/career"}
                  className="nav-link-off-canvas"
                  activeclassname="active"
                >
                  Career
                </NavLink>
              ) : (
                ""
              )}
              <NavLink
                to={"/contact"}
                className="nav-link-off-canvas"
                activeclassname="active"
                onClick={toggle}
              >
                Contact Us
              </NavLink>
              <NavLink
                onClick={handleNavbarLinkClick}
                to={"https://pie-profile.vercel.app/"}
                className="nav-link"
                activeclassname="active"
              >
                <button className="button-87">Portfolio</button>
              </NavLink>
            </Nav>
          </OffcanvasBody>
        </Offcanvas>
      </Navbar>
      <Outlet />
    </div>
  );
};

export default MainNavbar;
