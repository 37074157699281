import React, { useState } from "react";
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  Input,
  FormGroup,
} from "reactstrap";
import pearsonLogo from "../../images/pearson/mindhub.svg";
import data from "./pearsonData";
import "./styles.css";
import { IoIosLink } from "react-icons/io";

const Pearson = (args) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState(null);

  const startIndex = (currentPage - 1) * 15;
  const endIndex = Math.min(startIndex + 15, data.length);

  const filteredData = selectedFilter
    ? data.filter((item) => item.name.includes(selectedFilter))
    : data;

  const totalPages = Math.ceil(filteredData.length / 15);

  const currentData = filteredData.slice(startIndex, endIndex);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const handleFilterChange = (event) => {
    const selectedOption = event.target.value;
    setCurrentPage(1);
    if (selectedOption === "All") {
      setSelectedFilter(null);
    } else {
      setSelectedFilter(selectedOption);
    }
  };

  
  const openPDF = () => {
     window.open('./Pie_Technologies_2024.pdf', '_blank');
  }

  return (
    <div
      style={{
        marginTop: "6.1rem",
        padding: "2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="row bg-light"
        style={{
          paddingTop: "2rem",
          paddingBottom: "2rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="col-lg-4"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={pearsonLogo}
            alt="pearson-logo"
            style={{ height: "40%", width: "40%" }}
          />
        </div>
        <div
          className="col-lg-8 p-4 border-here"
          style={{
            borderLeft: "3px solid black",
            display: "flex",
            flexDirection:'column',
            justifyContent: "center",
            
            justifyItems:'start'
          }}
        >
          
          <p>
            The Information Technology Specialist program is a way for
            candidates to validate IT skills sought after by
            employers. The IT Specialist program is aimed at candidates who are
            considering or just beginning a path to a career in information
            technology. Learners can certify their knowledge in a broad range of
            IT topics, including software development, database administration,
            networking and security, mobility and device management, and coding.
            Learn how an IT Specialist certification can help jumpstart your
            career in tech.
          </p>
          <p onClick={openPDF} style={{cursor:'pointer'}}>Pie-technologies is an authorized training provider by Pearson <IoIosLink/></p>
        </div>
        <div
          className="col-lg-12"
          style={{
            paddingTop: "3rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="row d-flex justify-content-center"
            style={{
              paddingTop: "2rem",
              paddingBottom: "2rem",
            }}
          >
            <div
              className="col-lg-12"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="row gap-4 card-container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className="col-lg-5 col-md-5 col-sm-12"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FormGroup>
                    <Input
                      id="product-filter"
                      name="select"
                      type="select"
                      onChange={handleFilterChange}
                    >
                      <option value="All">All</option>
                      <option value="Certification Exam Voucher">
                        Certification Exam Voucher
                      </option>
                      <option value="Practice Test">Practice Test</option>
                      <option value="Courseware">Courseware</option>
                      <option value="Bundle">Bundle</option>
                    </Input>
                  </FormGroup>
                </div>
                <div
                  className="col-lg-5 col-md-5 col-sm-12"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <Pagination>
                    <PaginationItem disabled={currentPage === 1}>
                      <PaginationLink
                        previous
                        onClick={() => handlePageClick(currentPage - 1)}
                      />
                    </PaginationItem>
                    {[...Array(totalPages)].map((_, page) => (
                      <PaginationItem
                        key={page}
                        active={page + 1 === currentPage}
                        style={{
                          display: currentData.length > 0 ? "block" : "none",
                        }}
                      >
                        <PaginationLink
                          onClick={() => handlePageClick(page + 1)}
                        >
                          {page + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage === totalPages}>
                      <PaginationLink
                        next
                        onClick={() => handlePageClick(currentPage + 1)}
                        style={{
                          display: currentData.length > 0 ? "block" : "none",
                        }}
                      />
                    </PaginationItem>
                  </Pagination>
                </div>{" "}
                {currentData?.map((dat, index) => {
                  return (
                    <div
                      className="col-lg-4 col-md-6 col-sm-12 card-div"
                      key={index}
                      style={{
                        backgroundColor: "white",
                        width: "16rem",
                        borderRadius: "10px",
                        border: "2px solid #d3d3d3",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      <img
                        src={dat.image}
                        alt="course-image"
                        style={{
                          borderRadius: "10px",
                          marginTop: "12px",
                          transition: "transform 0.3s ease",
                          objectFit: "fill",
                          width: "100%",
                        }}
                      />
                      <div
                        className="details-div container"
                        style={{ height: "10rem" }}
                      >
                        <p style={{ fontSize: "1rem", fontWeight: "550" }}>
                          {dat.name}
                        </p>
                        <p style={{ fontSize: "15px" }}>{dat.price}$</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pearson;
