import { motion } from "framer-motion";

const SlideFromRight = ({ children, width = "fit-content", delay }) => {
  return (
    <div style={{ position: "relative", width, overflow: "hidden" }}>
      <motion.div
        variants={{
          hidden: { x: 200 },
          visible: { x: 0 },
        }}
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 1, ease: "easeIn" }}
      >
        {children}
      </motion.div>
    </div>
  );
};

export default SlideFromRight;
