import React from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

const AtlmsDetails = ({ isModalOpen, toggleModal }) => {

  return (
    <Modal isOpen={isModalOpen} toggle={toggleModal} centered size="xl">
      <ModalHeader
        className="text-center"
        style={{ background: "orange", textAlign: "center" }}
      >
        <p style={{ fontWeight: "bolder", fontSize: "1.5rem" }}>
          Pie-Technologies ATLMS Plans
        </p>
      </ModalHeader>
      <ModalBody className="my-4">
        <Row style={{ color: "black" }}>
          <Col
            lg={6}
            md={6}
            sm={6}
            xs={12}
            style={{ borderRight: "1px solid orange" }}
          >
            <div style={{ textAlign: "center", fontSize: "1.7rem" }}>
              <p style={{ fontWeight: "bold" }}>
                LMS (Learning Management System)
              </p>
            </div>
            <ul>
              <li style={{ fontWeight: "bold" }}>Admin Dashboard</li>
              <ul>
                <li>Priviliges and Permission Management</li>
                <li>Enroll Trainers/Trainees</li>
                <li>Create and Assign Batches</li>
                <li>Create Course Outlines</li>
                <li>Assign Courses</li>
                <li>View Feedbacks</li>
                <li>Track Course Coverage Progress</li>
                <li>Track Batch Progess</li>
                <li>Generate Multiple Reports</li>
              </ul>
              <li style={{ fontWeight: "bold" }}>Trainer Dashboard</li>
              <ul>
                <li>Trainee Attendance Management</li>
                <li>Course Coverage Tracking</li>
                <li>Upload Lectures</li>
                <li>Trainee Assignment Management</li>
                <li>Trainee Testing Management</li>
                <li>Trainee Management</li>
                <li>Trainee Progress Report</li>
              </ul>
              <li style={{ fontWeight: "bold" }}>Student Dashboard</li>
              <ul>
                <li>Access Lectures</li>
                <li>View Detailed Schedule</li>
                <li>Up-to-date Attendance</li>
                <li>Upload Assignment</li>
                <li>Attempt Test</li>
                <li>Give Feedback</li>
                <li>View Gradings</li>
              </ul>
            </ul>
            <div
              style={{
                textAlign: "center",
                color: "orange",
              }}
            >
              <p style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                <span style={{ border: "1px solid orange", padding: "10px" }}>
                  Price $299/m
                </span>
              </p>
            </div>
          </Col>
          <Col
            lg={6}
            md={6}
            sm={6}
            xs={12}
            style={{ borderRight: "1px solid orange" }}
          >
            <div style={{ textAlign: "center", fontSize: "1.7rem" }}>
              <p style={{ fontWeight: "bold" }}>ATLMS (ATS + LMS)</p>
            </div>
            <ul>
              <li style={{ fontWeight: "bold" }}>
                ATS (Admission and Testing System)
              </li>
              <ul>
                <li>Online Resgistrations</li>
                <li>online Testing</li>
                <li>Merit List</li>
                <li>Reporting</li>
              </ul>
              <li style={{ fontWeight: "bold" }}>
                LMS (Learning Management System)
              </li>
              <ul>
                <li>Admin Dashboard</li>
                <ul>
                  <li>Priviliges and Permission Management</li>
                  <li>Create Multiple Admins with Specified Roles</li>
                  <li>Enroll Trainers/Trainees</li>
                  <li>Create and Assign Batches</li>
                  <li>Create Course Outlines</li>
                  <li>Assign Courses</li>
                  <li>View Feedbacks</li>
                  <li>Track Course Coverage Progress</li>
                  <li>Track Batch Progess</li>
                  <li>Generate Multiple Reports</li>
                </ul>
                <li>Trainer Dashboard</li>
                <ul>
                  <li>Trainee Attendance Management</li>
                  <li>Course Coverage Tracking</li>
                  <li>Upload Lectures</li>
                  <li>Trainee Assignment Management</li>
                  <li>Trainee Testing Management</li>
                  <li>Trainee Management</li>
                  <li>Trainee Progress Report</li>
                </ul>
                <li>Student Dashboard</li>
                <ul>
                  <li>Access Lectures</li>
                  <li>View Detailed Schedule</li>
                  <li>Up-to-date Attendance</li>
                  <li>Upload Assignment</li>
                  <li>Attempt Test</li>
                  <li>Give Feedback</li>
                  <li>View Gradings</li>
                </ul>
              </ul>
            </ul>
            <div
              style={{
                textAlign: "center",
                color: "orange",
                marginTop: "3rem",
              }}
            >
              <p style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                <span style={{ border: "1px solid orange", padding: "10px" }}>
                  Price $399/m
                </span>
              </p>
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter style={{ background: "orange" }}>
        <Button color="secondary" onClick={toggleModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AtlmsDetails;
