import React from "react";
import "./IntroVideo.css";
const IntroVideo = () => {
  return (
    <div style={{display:"flex", justifyContent:"center"}}>
      <iframe
        title="PieTechnologiesVideo"
        width="100%"
        height="600"
        src="https://www.youtube.com/embed/3-iCDOYkfms?autoplay=1&loop=1&mute=1"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        loop 
      ></iframe>
    </div>
  );
};

export default IntroVideo;
