import React from "react";
import serviceImg from "../../../../images/backgrounds/Services2.jpg";
import borderImage from "../../../../images/borders/newBorder1.jpg";
import backImage from "../../../../images/backgrounds/newBg.svg";
import "./index.css";
import { Row, Col } from "reactstrap";
const serviceCards = [
  {
    title: "Web & Mobile Development",
    description: (
      <div>
        <ul>
          <li>React.Js/Next.js</li>
          <li>Node.Js/Express.Js</li>
          <li>Asp.Net</li>
          <li>MVC</li>
          <li>PHP(Codeigniter/Laravel)</li>
          <li>Android</li>
          <li>React Native</li>
          <li>Flutter</li>
        </ul>
      </div>
    ),
  },
  {
    title: "VAPT & Cyber Security Services",
    description: (
      <div>
        <ul>
          <li>Vulnerability assessment and penetration testing</li>
          <li>Defense readiness against real-world attacks</li>
          <li>Specialized teams for forensics and threat intelligence</li>
          <li>Protecting against digital threats</li>
          <li>Incident Response & Handling Management</li>
        </ul>
      </div>
    ),
  },
  {
    title: "App and Product Development",
    description: (
      <div>
        <ul>
          <li>Enterprise resource planning (ERP)</li>
          <li>Learning Management System (LMS)</li>
          <li>Account Management System (AMS)</li>
          <li>Communication Management System(CMS)</li>
          <li>Customer Relationship Management System(CRM)</li>
          <li>Emergency Alert Management System (EAMS)</li>
        </ul>
      </div>
    ),
  },
  {
    title: "Cloud and Cloud Native",
    description: (
      <div>
        <ul>
          <li>Azure, AWS & GCP</li>
          <li>Open Stack & Open Shift</li>
          <li>Dockers & Kubernetes</li>
          <li>Cloud site upgrade, maintain & migration</li>
        </ul>
      </div>
    ),
  },

  {
    title: "IT Managed Services",
    description: (
      <div>
        <ul>
          <li>L1, L2 & L3 Support</li>
          <li>CICD Services</li>
          <li>Proactive IT issue prediction and resolution</li>
          <li>System optimization & automation</li>
          <li>Cost-effective IT management</li>
        </ul>
      </div>
    ),
  },
  {
    title: "System Integrations",
    description: (
      <div>
        <ul>
          <li>Data center management & expansions</li>
          <li>Hardware & Software optimization</li>
          <li>Cloud and Cloud Native deployments</li>
          <li>Data Migration & Ansible Automation </li>
        </ul>
      </div>
    ),
  },
  {
    title: "IT Training & Certifications",
    description: (
      <div>
        <ul style={{ marginTop: "2rem" }}>
          <li>Blockchain</li>
          <li>Project Management</li>
          <li>Python Programing</li>
          <li>Big Data Analytics</li>
          <li>Cyber Security</li>
          <li>Digital Marketing & SEO</li>
          <li>Amazon Virtual Assistant</li>
          <li>Mern/Mean Stack Development</li>
          <li>Cloud and Native Computing</li>
          <li>Mobile Application Development </li>
          <li>SQA</li>
        </ul>
      </div>
    ),
  },
  {
    title: "Bootcamp Training Management",
    description: (
      <div>
        <ul>
          <li>Training Venues</li>
          <li>Computing facilities</li>
          <li>Trainers and Assistant Trainers</li>
          <li>Management Staff</li>
          <li>Refreshment and Meal</li>
          <li>Other Compliance Support</li>
        </ul>
      </div>
    ),
  },
  {
    title: "HR Consultancy Services",
    description: (
      <div>
        <ul>
          <li>Talent Acquisition</li>
          <li>Employees Engagement</li>
          <li>Training & Development</li>
          <li>Performance Management</li>
          <li>Organization Development</li>
          <li>Conflict Resolution</li>
          <li>Cost optimization</li>
          <li>Focus on core Business</li>
        </ul>
      </div>
    ),
  },
];

const ServicesMain = () => {
  return (
    <div className="bgx">
      <div
        className="pt-5 pb-5 bg-light"
        style={{
          backgroundImage: ` url(${backImage})`,
        }}
      >
        <div className="container">
          <p
            className=""
            style={{
              textTransform: "uppercase",
              fontSize: "2.5rem",
              fontWeight: "bold",
              textAlign: "center",
              color: "#333f52",
              marginBottom: "2rem",
            }}
          >
            Our Services
          </p>
          <Row className="justify-content-center align-items-center">
            {serviceCards.map((data) => (
              <Col
                sm={12}
                md={6}
                lg={6}
                xl={4}
                key={data.title}
                className="mb-5"
              >
                <div className="card shadow">
                  <div className="header">
                    <h2
                      className="text-center"
                      style={{ fontWeight: "bolder" }}
                    >
                      {data.title}
                    </h2>
                  </div>
                  <div className="body bg-light">{data.description}</div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ServicesMain;
